import React from 'react';

function Share({ color }) {
  return (
    <svg viewBox="0 0 82 80">
      <path
        stroke={color ? color : 'black'}
        strokeWidth="7"
        strokeLinecap="round"
        fill="transparent"
        d="M20.5 14.5001H9C5.68629 14.5001 3 17.1864 3 20.5001V71.0001C3 74.3138 5.68629 77.0001 9 77.0001H73.5C76.8137 77.0001 79.5 74.3138 79.5 71.0001V42.0001M23.8742 48.4412C26.2462 42.6485 35.2664 33.0464 56.0247 33.4729C56.5632 33.484 57 33.9291 57 34.4677V44.0886C57 44.9795 58.0771 45.4229 58.7071 44.793L78.8008 24.6993C79.1882 24.3119 79.1917 23.6848 78.8087 23.2931L59.215 3.25406C58.5884 2.61318 57.5 3.05685 57.5 3.95318V13.4145C57.5 13.9991 57.0028 14.458 56.4188 14.4305C44.1418 13.8514 22.1401 19.8184 23.4364 48.3583C23.4466 48.5815 23.7895 48.648 23.8742 48.4412Z"
      />
    </svg>
  );
}

export default Share;
